// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
// defineApp
export { defineApp } from './core/defineApp'
export type { RuntimeConfig } from './core/defineApp'
// plugins
export { getSketchJSON } from '/home/runner/work/GPT-Vis/GPT-Vis/.dumi/tmp-production/plugin-html2sketch';
// plugins types.d.ts
// @umijs/renderer-*
export { createBrowserHistory, createHashHistory, createMemoryHistory, Helmet, HelmetProvider, createSearchParams, generatePath, matchPath, matchRoutes, Navigate, NavLink, Outlet, resolvePath, useLocation, useMatch, useNavigate, useOutlet, useOutletContext, useParams, useResolvedPath, useRoutes, useSearchParams, useAppData, useClientLoaderData, useLoaderData, useRouteProps, useSelectedRoutes, useServerLoaderData, renderClient, __getRoot, Link, useRouteData, __useFetcher, withRouter } from '/home/runner/work/GPT-Vis/GPT-Vis/node_modules/.pnpm/@umijs+renderer-react@4.3.36_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@umijs/renderer-react';
export type { History, ClientLoader } from '/home/runner/work/GPT-Vis/GPT-Vis/node_modules/.pnpm/@umijs+renderer-react@4.3.36_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@umijs/renderer-react'
// umi/client/client/plugin
export { ApplyPluginsType, PluginManager } from '/home/runner/work/GPT-Vis/GPT-Vis/node_modules/.pnpm/umi@4.3.36_@babel+core@7.26.0_@types+node@22.10.2_@types+react@18.3.16_eslint@9.16.0_jiti@1.2_2wpmnn322pfixzbletjgznnz2y/node_modules/umi/client/client/plugin.js';
export { history, createHistory } from './core/history';
export { terminal } from './core/terminal';
// react ssr
export const useServerInsertedHTML: Function = () => {};
