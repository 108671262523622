// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import { frontmatter as fm0, toc as t0 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/custome-markdown.md?type=frontmatter';
import { demoIndex as dmi0 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/custome-markdown.md?type=demo-index';
import { frontmatter as fm1, toc as t1 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/customize-style.md?type=frontmatter';
import { demoIndex as dmi1 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/customize-style.md?type=demo-index';
import { frontmatter as fm2, toc as t2 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/antd-design-x.md?type=frontmatter';
import { demoIndex as dmi2 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/antd-design-x.md?type=demo-index';
import { frontmatter as fm3, toc as t3 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/quick-start.md?type=frontmatter';
import { demoIndex as dmi3 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/quick-start.md?type=demo-index';
import { frontmatter as fm4, toc as t4 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/custom-ui.md?type=frontmatter';
import { demoIndex as dmi4 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/custom-ui.md?type=demo-index';
import { frontmatter as fm5, toc as t5 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/index.md?type=frontmatter';
import { demoIndex as dmi5 } from '/home/runner/work/GPT-Vis/GPT-Vis/docs/index.md?type=demo-index';
import { frontmatter as fm6, toc as t6 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Area/index.md?type=frontmatter';
import { demoIndex as dmi6 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Area/index.md?type=demo-index';
import { frontmatter as fm7, toc as t7 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Bar/index.md?type=frontmatter';
import { demoIndex as dmi7 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Bar/index.md?type=demo-index';
import { frontmatter as fm8, toc as t8 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/ChartCodeRender/index.md?type=frontmatter';
import { demoIndex as dmi8 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/ChartCodeRender/index.md?type=demo-index';
import { frontmatter as fm9, toc as t9 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Column/index.md?type=frontmatter';
import { demoIndex as dmi9 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Column/index.md?type=demo-index';
import { frontmatter as fm10, toc as t10 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/ConfigProvider/index.md?type=frontmatter';
import { demoIndex as dmi10 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/ConfigProvider/index.md?type=demo-index';
import { frontmatter as fm11, toc as t11 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/DualAxes/index.md?type=frontmatter';
import { demoIndex as dmi11 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/DualAxes/index.md?type=demo-index';
import { frontmatter as fm12, toc as t12 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/FishboneDiagram/index.md?type=frontmatter';
import { demoIndex as dmi12 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/FishboneDiagram/index.md?type=demo-index';
import { frontmatter as fm13, toc as t13 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/FlowDiagram/index.md?type=frontmatter';
import { demoIndex as dmi13 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/FlowDiagram/index.md?type=demo-index';
import { frontmatter as fm14, toc as t14 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/GPTVis/index.md?type=frontmatter';
import { demoIndex as dmi14 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/GPTVis/index.md?type=demo-index';
import { frontmatter as fm15, toc as t15 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/HeatMap/index.md?type=frontmatter';
import { demoIndex as dmi15 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/HeatMap/index.md?type=demo-index';
import { frontmatter as fm16, toc as t16 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Histogram/index.md?type=frontmatter';
import { demoIndex as dmi16 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Histogram/index.md?type=demo-index';
import { frontmatter as fm17, toc as t17 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/IndentedTree/index.md?type=frontmatter';
import { demoIndex as dmi17 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/IndentedTree/index.md?type=demo-index';
import { frontmatter as fm18, toc as t18 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Line/index.md?type=frontmatter';
import { demoIndex as dmi18 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Line/index.md?type=demo-index';
import { frontmatter as fm19, toc as t19 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Map/index.md?type=frontmatter';
import { demoIndex as dmi19 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Map/index.md?type=demo-index';
import { frontmatter as fm20, toc as t20 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/MindMap/index.md?type=frontmatter';
import { demoIndex as dmi20 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/MindMap/index.md?type=demo-index';
import { frontmatter as fm21, toc as t21 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/NetworkGraph/index.md?type=frontmatter';
import { demoIndex as dmi21 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/NetworkGraph/index.md?type=demo-index';
import { frontmatter as fm22, toc as t22 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/OrganizationChart/index.md?type=frontmatter';
import { demoIndex as dmi22 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/OrganizationChart/index.md?type=demo-index';
import { frontmatter as fm23, toc as t23 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/PathMap/index.md?type=frontmatter';
import { demoIndex as dmi23 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/PathMap/index.md?type=demo-index';
import { frontmatter as fm24, toc as t24 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Pie/index.md?type=frontmatter';
import { demoIndex as dmi24 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Pie/index.md?type=demo-index';
import { frontmatter as fm25, toc as t25 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/PinMap/index.md?type=frontmatter';
import { demoIndex as dmi25 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/PinMap/index.md?type=demo-index';
import { frontmatter as fm26, toc as t26 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Radar/index.md?type=frontmatter';
import { demoIndex as dmi26 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Radar/index.md?type=demo-index';
import { frontmatter as fm27, toc as t27 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Scatter/index.md?type=frontmatter';
import { demoIndex as dmi27 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Scatter/index.md?type=demo-index';
import { frontmatter as fm28, toc as t28 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Text/index.md?type=frontmatter';
import { demoIndex as dmi28 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Text/index.md?type=demo-index';
import { frontmatter as fm29, toc as t29 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Treemap/index.md?type=frontmatter';
import { demoIndex as dmi29 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/Treemap/index.md?type=demo-index';
import { frontmatter as fm30, toc as t30 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/WordCloud/index.md?type=frontmatter';
import { demoIndex as dmi30 } from '/home/runner/work/GPT-Vis/GPT-Vis/src/WordCloud/index.md?type=demo-index';

export const filesMeta = {
  'docs/guide/custome-markdown': {
    frontmatter: fm0,
    toc: t0,
    demoIndex: dmi0,
    textGetter: () => import(/* webpackChunkName: "meta__docs" */'/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/custome-markdown.md?type=text'),
  },
  'docs/guide/customize-style': {
    frontmatter: fm1,
    toc: t1,
    demoIndex: dmi1,
    textGetter: () => import(/* webpackChunkName: "meta__docs" */'/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/customize-style.md?type=text'),
  },
  'docs/guide/antd-design-x': {
    frontmatter: fm2,
    toc: t2,
    demoIndex: dmi2,
    textGetter: () => import(/* webpackChunkName: "meta__docs" */'/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/antd-design-x.md?type=text'),
  },
  'docs/guide/quick-start': {
    frontmatter: fm3,
    toc: t3,
    demoIndex: dmi3,
    textGetter: () => import(/* webpackChunkName: "meta__docs" */'/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/quick-start.md?type=text'),
  },
  'docs/guide/custom-ui': {
    frontmatter: fm4,
    toc: t4,
    demoIndex: dmi4,
    textGetter: () => import(/* webpackChunkName: "meta__docs" */'/home/runner/work/GPT-Vis/GPT-Vis/docs/guide/custom-ui.md?type=text'),
  },
  'docs/index': {
    frontmatter: fm5,
    toc: t5,
    demoIndex: dmi5,
    textGetter: () => import(/* webpackChunkName: "meta__docs" */'/home/runner/work/GPT-Vis/GPT-Vis/docs/index.md?type=text'),
  },
  'components/Area/index': {
    frontmatter: fm6,
    toc: t6,
    demoIndex: dmi6,
    textGetter: () => import(/* webpackChunkName: "meta__Area" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Area/index.md?type=text'),
  },
  'components/Bar/index': {
    frontmatter: fm7,
    toc: t7,
    demoIndex: dmi7,
    textGetter: () => import(/* webpackChunkName: "meta__Bar" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Bar/index.md?type=text'),
  },
  'components/ChartCodeRender/index': {
    frontmatter: fm8,
    toc: t8,
    demoIndex: dmi8,
    textGetter: () => import(/* webpackChunkName: "meta__ChartCodeRender" */'/home/runner/work/GPT-Vis/GPT-Vis/src/ChartCodeRender/index.md?type=text'),
  },
  'components/Column/index': {
    frontmatter: fm9,
    toc: t9,
    demoIndex: dmi9,
    textGetter: () => import(/* webpackChunkName: "meta__Column" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Column/index.md?type=text'),
  },
  'components/ConfigProvider/index': {
    frontmatter: fm10,
    toc: t10,
    demoIndex: dmi10,
    textGetter: () => import(/* webpackChunkName: "meta__ConfigProvider" */'/home/runner/work/GPT-Vis/GPT-Vis/src/ConfigProvider/index.md?type=text'),
  },
  'components/DualAxes/index': {
    frontmatter: fm11,
    toc: t11,
    demoIndex: dmi11,
    textGetter: () => import(/* webpackChunkName: "meta__DualAxes" */'/home/runner/work/GPT-Vis/GPT-Vis/src/DualAxes/index.md?type=text'),
  },
  'components/FishboneDiagram/index': {
    frontmatter: fm12,
    toc: t12,
    demoIndex: dmi12,
    textGetter: () => import(/* webpackChunkName: "meta__FishboneDiagram" */'/home/runner/work/GPT-Vis/GPT-Vis/src/FishboneDiagram/index.md?type=text'),
  },
  'components/FlowDiagram/index': {
    frontmatter: fm13,
    toc: t13,
    demoIndex: dmi13,
    textGetter: () => import(/* webpackChunkName: "meta__FlowDiagram" */'/home/runner/work/GPT-Vis/GPT-Vis/src/FlowDiagram/index.md?type=text'),
  },
  'components/GPTVis/index': {
    frontmatter: fm14,
    toc: t14,
    demoIndex: dmi14,
    textGetter: () => import(/* webpackChunkName: "meta__GPTVis" */'/home/runner/work/GPT-Vis/GPT-Vis/src/GPTVis/index.md?type=text'),
  },
  'components/HeatMap/index': {
    frontmatter: fm15,
    toc: t15,
    demoIndex: dmi15,
    textGetter: () => import(/* webpackChunkName: "meta__HeatMap" */'/home/runner/work/GPT-Vis/GPT-Vis/src/HeatMap/index.md?type=text'),
  },
  'components/Histogram/index': {
    frontmatter: fm16,
    toc: t16,
    demoIndex: dmi16,
    textGetter: () => import(/* webpackChunkName: "meta__Histogram" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Histogram/index.md?type=text'),
  },
  'components/IndentedTree/index': {
    frontmatter: fm17,
    toc: t17,
    demoIndex: dmi17,
    textGetter: () => import(/* webpackChunkName: "meta__IndentedTree" */'/home/runner/work/GPT-Vis/GPT-Vis/src/IndentedTree/index.md?type=text'),
  },
  'components/Line/index': {
    frontmatter: fm18,
    toc: t18,
    demoIndex: dmi18,
    textGetter: () => import(/* webpackChunkName: "meta__Line" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Line/index.md?type=text'),
  },
  'components/Map/index': {
    frontmatter: fm19,
    toc: t19,
    demoIndex: dmi19,
    textGetter: () => import(/* webpackChunkName: "meta__Map" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Map/index.md?type=text'),
  },
  'components/MindMap/index': {
    frontmatter: fm20,
    toc: t20,
    demoIndex: dmi20,
    textGetter: () => import(/* webpackChunkName: "meta__MindMap" */'/home/runner/work/GPT-Vis/GPT-Vis/src/MindMap/index.md?type=text'),
  },
  'components/NetworkGraph/index': {
    frontmatter: fm21,
    toc: t21,
    demoIndex: dmi21,
    textGetter: () => import(/* webpackChunkName: "meta__NetworkGraph" */'/home/runner/work/GPT-Vis/GPT-Vis/src/NetworkGraph/index.md?type=text'),
  },
  'components/OrganizationChart/index': {
    frontmatter: fm22,
    toc: t22,
    demoIndex: dmi22,
    textGetter: () => import(/* webpackChunkName: "meta__OrganizationChart" */'/home/runner/work/GPT-Vis/GPT-Vis/src/OrganizationChart/index.md?type=text'),
  },
  'components/PathMap/index': {
    frontmatter: fm23,
    toc: t23,
    demoIndex: dmi23,
    textGetter: () => import(/* webpackChunkName: "meta__PathMap" */'/home/runner/work/GPT-Vis/GPT-Vis/src/PathMap/index.md?type=text'),
  },
  'components/Pie/index': {
    frontmatter: fm24,
    toc: t24,
    demoIndex: dmi24,
    textGetter: () => import(/* webpackChunkName: "meta__Pie" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Pie/index.md?type=text'),
  },
  'components/PinMap/index': {
    frontmatter: fm25,
    toc: t25,
    demoIndex: dmi25,
    textGetter: () => import(/* webpackChunkName: "meta__PinMap" */'/home/runner/work/GPT-Vis/GPT-Vis/src/PinMap/index.md?type=text'),
  },
  'components/Radar/index': {
    frontmatter: fm26,
    toc: t26,
    demoIndex: dmi26,
    textGetter: () => import(/* webpackChunkName: "meta__Radar" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Radar/index.md?type=text'),
  },
  'components/Scatter/index': {
    frontmatter: fm27,
    toc: t27,
    demoIndex: dmi27,
    textGetter: () => import(/* webpackChunkName: "meta__Scatter" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Scatter/index.md?type=text'),
  },
  'components/Text/index': {
    frontmatter: fm28,
    toc: t28,
    demoIndex: dmi28,
    textGetter: () => import(/* webpackChunkName: "meta__Text" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Text/index.md?type=text'),
  },
  'components/Treemap/index': {
    frontmatter: fm29,
    toc: t29,
    demoIndex: dmi29,
    textGetter: () => import(/* webpackChunkName: "meta__Treemap" */'/home/runner/work/GPT-Vis/GPT-Vis/src/Treemap/index.md?type=text'),
  },
  'components/WordCloud/index': {
    frontmatter: fm30,
    toc: t30,
    demoIndex: dmi30,
    textGetter: () => import(/* webpackChunkName: "meta__WordCloud" */'/home/runner/work/GPT-Vis/GPT-Vis/src/WordCloud/index.md?type=text'),
  },
}

export { tabs as tabsMeta } from './tabs';
